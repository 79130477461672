import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { FIELDS, getField } from "../../store/form/fields";
import { truthy, toBooleanString } from "../../utils";
import FormSection from "../../components/FormSection";
import { open } from "../../store/snack/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { FieldRow, FieldSection, FieldSlot } from "../../components/Forms/FieldSection";
import Checkbox from "../../components/Checkbox";
import HiddenInput from "../../components/Utility/HiddenInput";
import DropdownAnswer from "../../components/answer/DropdownAnswer";
import HighSchoolAnswer from "../../components/answer/HighSchoolAnswer";
import DateAnswer, { DateResolution } from "../../components/answer/DateAnswer";
import BooleanAnswer from "../../components/answer/BooleanAnswer";
import DecimalAnswer from "../../components/answer/DecimalAnswer";
import TextAnswer from "../../components/answer/TextAnswer";
import CertifyAnswer from "../../components/answer/CertifyAnswer";
import { Alert } from "@material-ui/core";

/**
 * stadard High School section
 * 
 * @param {function} param.onChange 
 * @param {function} param.onCancel 
 * @param {SectionHelper} param.section 
 * @returns {Component}
 */
function EditHighschool({
    hideCorrectional = false,
    onChange,
    onCancel,
    section
}) {


    const methods = useForm({
        mode: "onBlur",
    });

    const {
        errors,
        formState,
        getValues,
        setValue,
        handleSubmit,
        watch,
    } = methods;



 
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const NO_GPA_FLAG = "0.01";
    const FIELD_NoLetterGrades = "NoLetterGrades";
    const watchGpa = watch(FIELDS.HIGHSCHOOL_GPA);
    const watchNoLetterGrades = watch(FIELD_NoLetterGrades);

    useEffect(() => {

        if (`${watchGpa}` === NO_GPA_FLAG) {
            setValue(FIELDS.HIGHSCHOOL_GPA, "");
            setValue(FIELD_NoLetterGrades, true);
        }

    }, [watchGpa]);

 
    const onSubmit = async (data, e) => {
        try {
            setLoading(true);
            if (watchNoLetterGrades === true) {
                data[FIELDS.HIGHSCHOOL_GPA] = NO_GPA_FLAG;
            }
            if (data[FIELDS.CORRECTIONAL_FACILITY_RELEASE_DATE]) {
                data[FIELDS.CORRECTIONAL_FACILITY_RELEASE_DATE] = moment(
                    data[FIELDS.CORRECTIONAL_FACILITY_RELEASE_DATE]
                ).format("YYYY-MM");
            }           
            // Cleanup the HS dropdown pre-selection, when Unlisted HS check box is checked.
            if (data.HIGHSCHOOL?.UnlistedName) {
                data.UnlistedHS = true;
                data.HIGHSCHOOL.SchoolCode = null;
            }
            if (data[FIELDS.HIGHSCHOOL_TYPE] === 'UNSTD') {
                data.UnlistedHS = false;
                data.HIGHSCHOOL = {
                    UnlistedName: null,
                    SchoolCode: null
                };
                data[FIELDS.HIGHSCHOOL_GRAD_DATE] = null;
            }

            const results = await section.saveAnswers(data, dispatch);
            if (results){
                if (results.isDeadlineImpacted) {
                    dispatch(
                        open({
                            message: "High school change could impact your Oregon Promise deadline. Please check your Oregon Promise application for more details.",
                            severity: "success",
                        })
                    );
                }
                if (results.isOptionsImpacted) {
                    dispatch(
                        open({
                            message: "Your Oregon Promise cohort could not be determined from your changes. Please check your Oregon Promise application.",
                            severity: "success",
                        })
                    );
                }
                if (section.completion.isIncorrect){
                    dispatch(
                        open({
                            message: "High School information is incomplete or incorrect. Please review and correct the information.",
                            severity: "error",
                        })
                    );
                }
                else if (typeof onChange === "function") {
                    onChange(true);
                }             
            }
        }
        finally {
            setLoading(false);
        }                                       

    };

    const onError = (errors, e) => {
        // console.log('ERROR',errors, e);
    };

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };

    const watchHighSchoolType = watch(FIELDS.HIGHSCHOOL_TYPE);
    const gedSectionRequired = watchHighSchoolType === "GED" || watchHighSchoolType === "GEDHM";


    const watchCorrectionalFacilityType = watch(FIELDS.CORRECTIONAL_FACILITY_TYPE);
    const customFacName = watchCorrectionalFacilityType == 3 || watchCorrectionalFacilityType == 4 || watchCorrectionalFacilityType == 5 || watchCorrectionalFacilityType == 6;

    const watchGedScoreFlag = watch(FIELDS.GED_IN_PROGRESS_FLAG);
    const gedScoreRequired = !(watchGedScoreFlag === true || watchGedScoreFlag === "True");
    useEffect(() => {
        // console.log("GED<sup style={{ verticalAlign: "super", fontSize: ".9rem"}}>&reg;</sup> SCORE FLAG CHANGED", watchGedScoreFlag)
        if (watchGedScoreFlag === true || watchGedScoreFlag === "True") {
            setValue(FIELDS.GED_SCORE, "");
        }
    }, [watchGedScoreFlag]);

    // BooleanRadioGroup Watchers
    const correctionalFacilityHsAnswer = section.getAnswerByField(FIELDS.CORRECTIONAL_FACILITY_HS);
    const watchCorrectionalHs = watch(FIELDS.CORRECTIONAL_FACILITY_HS, correctionalFacilityHsAnswer.Response);
    const watchCorrectionalComColl = watch(FIELDS.CORRECTIONAL_FACILITY_CC)
    const watchAspireMember = watch(FIELDS.ASPIRE__MEMBER)


    const highSchoolCompletion = section.completion.get("HighSchool");

    const seniorYear = section.year;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <FormSection
                    title="High School Information"
                    isEditable={true}
                    onSaveClick={handleSubmit(onSubmit, onError)}
                    onCancelClick={onCancel}
                    loading={loading}
                >
                    <FieldSection title="High School, Home School or GED&reg;" isRequired>
                        {!hideCorrectional && (
                            <FieldRow>
                                <FieldSlot>
                                    <p>
                                        Did you (or will you) obtain your high school diploma or
                                        GED® test transcript while in a{" "}
                                        <strong>correctional facility in Oregon</strong>?

                                        <i>
                                            &nbsp; (Note: You can still be eligible for Oregon Promise if you
                                            answer YES, per HB 2910 (2019).)
                                        </i>
                                    </p>
                                </FieldSlot>
                                <FieldSlot>
                                    <BooleanAnswer
                                        answer={section.getAnswerByField(FIELDS.CORRECTIONAL_FACILITY_CC)}
                                        isRequired={true}
                                        value={watchCorrectionalComColl}
                                    />{" "}
                                </FieldSlot>
                                {truthy(watch(FIELDS.CORRECTIONAL_FACILITY_CC)) === true && (
                                    <FieldSlot>
                                        <FieldSection withDivider={false}>
                                            <FieldRow>
                                                <FieldSlot>
                                                    <BooleanAnswer
                                                        answer={section.getAnswerByField(FIELDS.CORRECTIONAL_FACILITY_HS)}
                                                        required={true}
                                                        label={<>Do you plan to attend community college while you are in a correctional facility in Oregon? <span style={{ color: 'red' }}> *</span></>}
                                                    />
                                                </FieldSlot>
                                            </FieldRow>
                                            {toBooleanString(watchCorrectionalHs) === 'False' && (
                                                <>
                                                    <FieldRow>
                                                        <FieldSlot>
                                                            <DropdownAnswer answer={section.getAnswerByField(FIELDS.CORRECTIONAL_FACILITY_TYPE)}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                                variant="outlined"
                                                                isRequired={true}
                                                                label="Which type of facility were you housed in when you obtained your high school diploma or GED® test transcript?"
                                                            />
                                                        </FieldSlot>
                                                    </FieldRow>
                                                    <FieldRow>
                                                        {customFacName ? (
                                                            <FieldSlot>
                                                                <TextAnswer
                                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                                    answer={section.getAnswerByField(FIELDS.CORRECTIONAL_FACILITY_NAME)}
                                                                    isRequired={true}
                                                                    inputLabel="Please enter your facility name"
                                                                />
                                                            </FieldSlot>
                                                        ) : (
                                                            <HiddenInput
                                                                name={FIELDS.CORRECTIONAL_FACILITY_NAME}
                                                                value=""
                                                            />
                                                        )}
                                                    </FieldRow>
                                                    <FieldRow>
                                                        <FieldSlot>
                                                            <DateAnswer
                                                                resolution={DateResolution.MONTH}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                                answer={section.getAnswerByField(FIELDS.CORRECTIONAL_FACILITY_RELEASE_DATE)}
                                                                isRequired={false}
                                                                readOnlyInput={true}
                                                                placeholder="Use the calendar icon to enter in your release date"
                                                                label="What is (or was) your release date?"
                                                            />
                                                        </FieldSlot>
                                                    </FieldRow>
                                                    <FieldRow>
                                                        <FieldSlot>
                                                            <CertifyAnswer
                                                                isRequired={false}
                                                                answer={section.getAnswerByField(FIELDS.CORRECTIONAL_FACILITY_VERIFICATION_ALLOW)}
                                                                label="I agree to allow my facility to verify certain information related to my incarceration and my graduation date."
                                                            />{" "}
                                                            {truthy(watch(FIELDS.CORRECTIONAL_FACILITY_VERIFICATION_ALLOW)) == false &&
                                                                <div>
                                                                    <p>
                                                                        <i className="required">If you do not agree, then you may or may not be eligible for Oregon Promise based on your graduation date. Contact OSAC for more details: (541)687-7400 or <a href="mailto:OregonPromise@hecc.oregon.gov">OregonPromise@hecc.oregon.gov</a>.</i>
                                                                    </p>
                                                                </div>
                                                            }
                                                        </FieldSlot>
                                                    </FieldRow>
                                                </>
                                            )}
                                        </FieldSection>
                                    </FieldSlot>
                                )}
                            </FieldRow>
                        )}
                        <FieldRow>
                            <FieldSection title="School or Equivalent" withDivider={false}>
                                <FieldRow>
                                    <FieldSlot>
                                        <DropdownAnswer answer={section.getAnswerByField(FIELDS.HIGHSCHOOL_TYPE)}
                                            isRequired={true}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            label="Type of High School or Equivalent"
                                        />
                                    </FieldSlot>

                                    <HighSchoolAnswer answer={section.getAnswerByField(FIELDS.HIGHSCHOOL)} hstype={watchHighSchoolType} />

                                    <FieldSlot>
                                        <DateAnswer
                                            answer={section.getAnswerByField(FIELDS.HIGHSCHOOL_GRAD_DATE)}
                                            resolution={DateResolution.MONTH}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            isRequired={true}
                                            label="Date of High School Completion"
                                        />
                                    </FieldSlot>
                                </FieldRow>

                                {highSchoolCompletion.isInconsistent &&
                                    <Alert severity="error" className="alert alert--error">
                                        This high school was no longer open the date you graduated. Please correct either your high school or your graduation date.
                                    </Alert>
                                }

                            </FieldSection>
                        </FieldRow>
                    </FieldSection>

                    {gedSectionRequired === true && (
                        <FieldSection title="GED&reg; Score">
                            <FieldRow>
                                <FieldSlot>
                                    <DecimalAnswer
                                        answer={section.getAnswerByField(FIELDS.GED_SCORE)}
                                        disabled={!gedScoreRequired}
                                        inputLabel="GED&reg; Score"
                                        isRequired={gedScoreRequired}
                                    />
                                </FieldSlot>
                            </FieldRow>
                            <FieldRow>
                                <FieldSlot>
                                    <CertifyAnswer answer={section.getAnswerByField(FIELDS.GED_IN_PROGRESS_FLAG)}
                                        label="I will not have my GED&reg; requirements completed by the time I submit this application." />
                                </FieldSlot>
                            </FieldRow>
                        </FieldSection>
                    )}

                    {watchHighSchoolType !== 'GED' && watchHighSchoolType !== 'UNSTD' &&
                        <FieldSection title="HIGH SCHOOL 5TH YEAR" isRequired>
                            <BooleanAnswer answer={section.getAnswerByField(FIELDS.FIFTH_YEAR_SENIOR)} isRequired={true}
                                label={"I will be a high school 5th year senior attending college in Fall term " + seniorYear} />
                        </FieldSection>
                    }

                    {gedSectionRequired === false && watchHighSchoolType !== 'UNSTD' ? (
                        <FieldSection title={getField(FIELDS.HIGHSCHOOL_GPA).question}>
                            <FieldRow>
                                <FieldSlot>
                                    <DecimalAnswer answer={section.getAnswerByField(FIELDS.HIGHSCHOOL_GPA)}
                                        disabled={watchNoLetterGrades}
                                        isRequired={!watchNoLetterGrades}
                                        inputLabel="High School GPA"
                                    />
                                </FieldSlot>
                                <FieldSlot container alignItems="flex-end">
                                    <Checkbox name={FIELD_NoLetterGrades} label="My high school did not assign letter grades." disabled={section.getAnswerByField(FIELDS.HIGHSCHOOL_GPA)?.IsLocked} />
                                </FieldSlot>
                            </FieldRow>
                        </FieldSection>
                    ) : (
                        ""
                    )}

                    <FieldSection title="Did you work with the ASPIRE program in high school?">
                        <FieldRow>
                            <FieldSlot>
                                <BooleanAnswer answer={section.getAnswerByField(FIELDS.ASPIRE__MEMBER)} value={watchAspireMember} />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection withDivider={true}>
                        <FieldRow>
                            <FieldSlot>
                                <TextAnswer
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    answer={section.getAnswerByField(FIELDS.ASPIRE__ADVISOR)}
                                    inputLabel="ASPIRE Mentor’s Name"
                                />
                            </FieldSlot>
                            <FieldSlot>
                                <TextAnswer
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    answer={section.getAnswerByField(FIELDS.ASPIRE__SITE)}
                                    inputLabel="ASPIRE Site Name"
                                />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditHighschool;
